<template>
    <div class="main-content">
        <pagesHeader title="章节内容管理" />
        <div class="table-content">
            <avue-crud :data="data" :option="option" :search.sync="search" :page.sync="pageSync" :table-loading="tableLoading" @on-load="onLoad" ref="crud">
                <template slot="menuLeft">
                    <div style="display: flex;width: 100%;">
                        <el-button type="primary" icon="el-icon-plus" size="medium" style="margin-left: 15px" @click.stop="chapter = true ,submitType=1">新增章节</el-button>
                        <span>&emsp;章节ID:&emsp;</span>
                        <el-input clearable v-model="search.id"    prefix-icon="el-icon-search" style="width: 250px" ></el-input>
                        <span>&emsp;章节名称:&emsp;</span>
                        <el-input clearable v-model="search.name"   prefix-icon="el-icon-search" style="width: 250px" ></el-input>
                        <span>&emsp;题库名称:&emsp;</span>
                        <div style="width: 150px;margin: 0 10px;">
                            <el-select clearable v-model="search.questionId" >
                                <el-option v-for="item in serachQuestionList" :key="item.value" :label="item.label" :value="item.value">
                                </el-option>
                            </el-select>
                        </div>
                        <span>&emsp;状态:</span>
                        <div style="width: 100px;margin: 0 10px;">
                            <el-select clearable v-model="search.state" >
                                <el-option v-for="item in typeoption" :key="item.value" :label="item.label" :value="item.value">
                                </el-option>
                            </el-select>
                        </div>
                        <el-button type="primary" size="medium" style="margin-left: 15px" @click.stop="searchList">&emsp;查询&emsp;</el-button>
                    </div>
                </template>
                <template slot="isShows" slot-scope="scope">
                    <avue-switch v-model="scope.row.isShows" @click="rowState(scope.row)"></avue-switch>
                </template>
                <template slot="menu" slot-scope="{ row }">

                    <el-button style="margin-left:10px;" size="small" type="text" @click.stop="editChapter(row.id)">编辑</el-button>
                    <el-button style="margin-left:10px;" size="small" type="text" @click.stop="toChapterQuestionList(row.id)">测试题管理</el-button>
                    <el-button style="margin-left:10px;" size="small" type="text" @click.stop="deleteChapter(row.id)">删除</el-button>
                </template>
            </avue-crud>
        </div>
        <chapterListDialog  :serachQuestionList='addserachQuestionList' :search="search" :submitType='submitType' :form="form" v-if="chapter" :dialogVisible="chapter"></chapterListDialog>
    </div>
</template>

<script>
    import chapterListDialog from './chapterListDialog.vue';
    import { chapterList,chapterView,setChapterState,chapterDelete,chooseList } from '@/api/question/questionList';

    export default {
        name: 'chapterList',
        components: {
            chapterListDialog
        },
        data() {
            return {
                schoolTypeList:[],
                provincelist: [],
                submitType:1,
                serachQuestionList:[],
                addserachQuestionList:[],
                typeoption: [{
                    value: null,
                    label: '全部'
                }, {
                    value: 1,
                    label: '启用'
                }, {
                    value: 2,
                    label: '禁用'
                }],
                search: {
                    state:null,
                    questionId:''
                },
                form: { },
                school: false,
                book:false,
                question:false,
                chapter:false,
                nameOrPhone:'',
                typevalue: '',
                date: '',
                tableLoading: false,
                exportLoading: false,
                data: [],
                option: {
                    emptyText: '暂无数据',
                    stripe: false,
                    showHeader: true,
                    border: true,
                    align: 'center',
                    menuAlign: 'center',
                    columnBtn: false, //列显隐
                    refreshBtn: false, //刷新
                    saveBtn: false,
                    updateBtn: false,
                    cancelBtn: false,
                    addBtn: false,
                    // saveBtnTitle:'11',
                    delBtn: false,
                    editBtn: false,
                    excelBtn: false,
                    //index:true,
                    //indexLabel:'序号',
                    column: [
                        {
                            label: '章节ID',
                            prop: 'id'

                        },
                        {
                            label: '章节名称',
                            prop: 'name'
                        },
                        {
                            label: '所属题库名称',
                            prop: 'questionName'
                        },
                        {
                            label: '状态',
                            prop: 'isShows',
                            type: 'switch',
                            slot: false,
                            row: true
                        },
                        {
                            label: '排序值',
                            prop: 'sort'
                        }

                    ]
                },
                page: 1,
                pageSize: 10,
                pageSync: {
                    currentPage: 1,
                    total: 0,
                    layout: 'total,prev,pager, next',
                    background: false,
                    pageSize: 10
                }
            };
        },

        mounted() {
            let that = this;
            that.$root.eventHub.$on('addchapter_chapteList', n => {
                that.chapter = false;
            });
            that.$root.eventHub.$on('chapterAdd_chapterList', n => {
                that.chapter = false;
                if (n) {
                    that.getchapterList();
                }
            });
        },
        methods: {

            //获取章节列表
            getchapterList() {
                this.tableLoading = true;
                chapterList({
                    page: this.pageSync.currentPage,
                    size: this.pageSync.pageSize,
                    id: this.search.id?this.search.id:'',
                    name: this.search.name?this.search.name:'',
                    questionId: this.search.questionId?this.search.questionId:'',
                    state: this.search.state?this.search.state:null
                })
                    .then(res => {
                        this.tableLoading = false;
                        if(res.code===200||res.success){
                            res.data.records.forEach((item) => {
                                if (item.state == 1) {
                                    item['isShows'] = true;
                                } else {
                                    item['isShows'] = false;
                                }
                            });
                            this.data = res.data.records;
                            console.log(this.data);
                            this.pageSync.total = res.data.total;
                            this.pageSync.currentPage = res.data.current;
                        }else{
                            this.$message.error(res.msg);
                        }
                    })
                    .catch(res => {
                        this.tableLoading = false;
                        this.$message.error(res.msg);
                    });
            },

            searchList(){
                this.pageSync.currentPage = 1;
                this.getchapterList()
            },

            //章节编辑回显
            editChapter(id){
                this.submitType=2
                chapterView(id).then(res=>{
                    if(res.code==200){
                        this.form=res.data
                        this.chapter=true
                    }else{
                        this.$message.error(res.msg);
                    }
                }).catch(res=>{
                    this.$message.error(res.msg);
                })
            },

            //改变章节状态
            rowState(row) {
                let that = this;
                setChapterState({
                    id: row.id,
                    state: row.state == 1 ? 2 : 1
                })
                    .then(res => {
                        that.getchapterList();
                        if(res.success){
                            this.$message.success(res.msg);
                        }else{
                            this.$message.error(res.msg);
                        }
                    })
                    .catch(res => {
                        that.getchapterList();
                        this.$message.error(res.msg);
                    });
            },

            //章节删除
            deleteChapter(id) {
                let that = this;
                console.log(id);
                this.$confirm(`删除后关联数据均会删除，再次确认是否删除！`, '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    callback: action => {
                        if (action == 'confirm') {
                            chapterDelete(id).then(res => {
                                // 获取数据成功后的其他操作
                                if (res.code === 200) {
                                    that.getchapterList();
                                    that.$message.success(res.msg);
                                } else {
                                    that.$message.error(res.msg);
                                }
                            });
                        }
                    }
                });
            },

            toChapterQuestionList(id){
                this.$router.push({path:'/web/question/chapterquestion/list',query: {id}})
            },

            //题库名称列表
            chooseQuestionList() {
                this.serachQuestionList =[];
                this.addserachQuestionList = [];
                chooseList({}).then(res => {
                    if (res.code == 200) {
                        var bd ={
                            label:'全部',
                            value:''
                        } ;
                        this.serachQuestionList.push(bd),
                        res.data.forEach(item => {
                            item['label'] = item.questionName;
                            item['value'] = item.questionId;
                            this.serachQuestionList.push(item);
                            this.addserachQuestionList.push(item);
                        });
                    }
                });
            },
            //加载数据
            onLoad() {
                this.getchapterList(),
                this.chooseQuestionList()
            },
        }
    };
</script>

<style scoped>
    span{
        line-height: 35px;
    }
</style>
