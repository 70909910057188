import { Delete, get, post, put } from '../axios'
//获取书目列表
export const bookList = params => get(`/web/book/manage/list`,params);
//编辑回显
export const detailView = params => get(`/web/book/manage/detailView/${params}`);
//新增
export const bookAdd = params => post(`/web/book//manage/add`,params);
//编辑
export const bookEdit = params => put(`/web/book/manage/edit`,params);
//状态修改
export const setBookState = params => put(`/web/book/manage/updateState`,params);
//书目管理-状态再次确认
export const updateConfirm = params => Delete(`/web/book/manage/updateConfirm/${params}`);
//删除
export const deleteBook = params => Delete(`/web/book/manage/delete/${params}`);

//知识点列表
export const bookPointsList = params => get(`/web/book/manage/bookPointsList`,params);
//知识点编辑回显
export const bookPointsView = params => get(`/web/book/manage/bookPointsView/${params}`);
//状态修改
export const bookPointsUpdate = params => put(`/web/book/manage/bookPointsUpdate`,params);
//删除知识点
export const deleteBookPoints = params => Delete(`/web/book/manage/bookPointsDelete/${params}`);
//新增知识点
export const bookPointsAdd = params => post(`/web/book/manage/bookPointsAdd`,params);
//编辑知识点
export const bookPointsEdit = params => put(`/web/book/manage/bookPointsEdit`,params);


