<template>
    <div class="main-content">
        <pagesHeader title="积分记录" />
        <div class="table-content">
            <avue-crud :data="data" :option="option" :search.sync="search" :page.sync="pageSync" :table-loading="tableLoading" @on-load="onLoad" ref="crud">
            </avue-crud>
        </div>
        <el-button @click="returnPrevious()" style="margin-bottom: 10px;float:right">返回</el-button>
    </div>
</template>

<script>

    import { integralList } from '@/api/user/userList';


    export default {
        name: 'integralRecord',
        data() {
            return {
                form: { },
                school: false,
                typevalue: '',
                date: '',
                tableLoading: false,
                exportLoading: false,
                search: {state:null},
                data: [],
                option: {
                    menu:false,
                    emptyText: '暂无数据',
                    stripe: false,
                    showHeader: true,
                    border: true,
                    align: 'center',
                    menuAlign: 'center',
                    columnBtn: false, //列显隐
                    refreshBtn: false, //刷新
                    saveBtn: false,
                    updateBtn: false,
                    cancelBtn: false,
                    addBtn: false,
                    // saveBtnTitle:'11',
                    delBtn: false,
                    editBtn: false,
                    excelBtn: false,
                    //index:true,
                    column: [
                        {
                            label: '明细',
                            prop: 'details'
                        },
                        {
                            label: '时间',
                            prop: 'createTime'
                        },
                        {
                            label: '积分',
                            prop: 'integralResult',
                        }
                    ]
                },
                page: 1,
                pageSize: 10,
                pageSync: {
                    currentPage: 1,
                    total: 0,
                    layout: 'total,prev,pager, next',
                    background: false,
                    pageSize: 10
                }
            };
        },

        methods: {

            //获取积分记录列表
            getIntegralList(){
                this.tableLoading = true;
                integralList({
                    page: this.pageSync.currentPage,
                    size: this.pageSync.pageSize,
                    id: this.$route.query.id
                })
                    .then(res => {
                        this.tableLoading = false;
                        if(res.code===200||res.success){
                            this.data = res.data.records;
                            console.log(this.data);
                            this.pageSync.total = res.data.total;
                            this.pageSync.currentPage = res.data.current;
                        }else{
                            this.$message.error(res.msg);
                        }
                    })
                    .catch(res => {
                        this.tableLoading = false;
                        this.$message.error(res.msg);
                    });
            },

            //返回上一页
            returnPrevious(){
                this.$router.go(-1);
            },
            //加载数据
            onLoad() {
                this.getIntegralList()
            },
        }
    };
</script>

<style scoped></style>
