<template>
    <div class="main-content">
        <pagesHeader title="题库设置" />
        <div class="table-content">
            <avue-crud :data="data" :option="option" :search.sync="search" :page.sync="pageSync" :table-loading="tableLoading" @on-load="onLoad" ref="crud">
                <template slot="menuLeft">
                    <div style="display: flex;width: 100%;">
                        <el-button type="primary" icon="el-icon-plus" size="medium" style="margin-left: 15px" @click.stop="question = true ,submitType=1">新增题库</el-button>
                        <span>&emsp;题库ID:&emsp;</span>
                        <el-input clearable v-model="search.id"    prefix-icon="el-icon-search" style="width: 250px" ></el-input>
                        <span>&emsp;题库名称:&emsp;</span>
                        <el-input clearable v-model="search.name"   prefix-icon="el-icon-search" style="width: 250px" ></el-input>
                        <span>&emsp;状态:</span>
                        <div style="width: 100px;margin: 0 10px;">
                            <el-select clearable v-model="search.state" >
                                <el-option v-for="item in typeoption" :key="item.value" :label="item.label" :value="item.value">
                                </el-option>
                            </el-select>
                        </div>
                        <el-button type="primary" size="medium" style="margin-left: 15px" @click.stop="searchList">&emsp;&emsp;查询&emsp;&emsp;</el-button>
                    </div>
                </template>
                <template slot="isShows" slot-scope="scope">
                    <avue-switch v-model="scope.row.isShows" @click="rowState(scope.row)"></avue-switch>
                </template>
                <template slot="menu" slot-scope="{ row }">

                    <el-button style="margin-left:10px;" size="small" type="text" @click.stop="editQuestion(row.id)">编辑</el-button>
                    <el-button style="margin-left:10px;" size="small" type="text" @click.stop="getQueryChapter(row.id)">删除</el-button>
                </template>
            </avue-crud>
        </div>
        <questionListDialog  :submitType='submitType' :form="form" v-if="question" :dialogVisible="question"></questionListDialog>
        <el-dialog
                :title='reminder'
                :visible.sync="dialogVisible"
                width="30%"
                :before-close="handleClose">
            <!--<span>{{tip}}</span>-->
            <div v-html="tip"></div>
            <span slot="footer" class="dialog-footer">
            <el-button v-if="showCancel" @click="dialogVisible = false">取 消</el-button>
            <el-button type="primary" @click="post_dialogVisible">确 定</el-button>
          </span>
        </el-dialog>
    </div>
</template>

<script>
    import questionListDialog from './questionListDialog.vue';
    import { questionList,questionView,setQuestionState,queryChapter,questionDelete } from '@/api/question/questionList';

    export default {
        name: 'questionList',
        components: {
            questionListDialog
        },
        data() {
            return {
                qid:'',
                reminder:'',
                dialogVisible:false,
                showCancel:false,
                tip:'',
                schoolTypeList:[],
                provincelist: [],
                submitType:1,
                typeoption: [{
                    value: null,
                    label: '全部'
                }, {
                    value: 1,
                    label: '启用'
                }, {
                    value: 2,
                    label: '禁用'
                }],
                search: {state:null},
                form: { },
                school: false,
                book:false,
                question:false,
                nameOrPhone:'',
                typevalue: '',
                date: '',
                tableLoading: false,
                exportLoading: false,
                data: [],
                option: {
                    emptyText: '暂无数据',
                    stripe: false,
                    showHeader: true,
                    border: true,
                    align: 'center',
                    menuAlign: 'center',
                    columnBtn: false, //列显隐
                    refreshBtn: false, //刷新
                    saveBtn: false,
                    updateBtn: false,
                    cancelBtn: false,
                    addBtn: false,
                    // saveBtnTitle:'11',
                    delBtn: false,
                    editBtn: false,
                    excelBtn: false,
                    //index:true,
                    //indexLabel:'序号',
                    column: [
                        {
                            label: '题库ID',
                            prop: 'id'

                        },
                        {
                            label: '题库名称',
                            prop: 'name'
                        },
                        {
                            label: '题库封面图',
                            prop: 'cover',
                            type: 'upload'
                        },
                        {
                            label: '状态',
                            prop: 'isShows',
                            type: 'switch',
                            slot: false,
                            row: true
                        },
                        {
                            label: '排序值',
                            prop: 'sort'
                        }

                    ]
                },
                page: 1,
                pageSize: 10,
                pageSync: {
                    currentPage: 1,
                    total: 0,
                    layout: 'total,prev,pager, next',
                    background: false,
                    pageSize: 10
                }
            };
        },

        mounted() {
            let that = this;
            that.$root.eventHub.$on('addquestion_questionList', n => {
                that.question = false;
            });
            that.$root.eventHub.$on('questionAdd_questionList', n => {
                that.question = false;
                if (n) {
                    that.getquestionList();
                }
            });
        },
        methods: {

            //获取题库列表
            getquestionList() {
                this.tableLoading = true;
                questionList({
                    page: this.pageSync.currentPage,
                    size: this.pageSync.pageSize,
                    id: this.search.id?this.search.id:'',
                    name: this.search.name?this.search.name:'',
                    state: this.search.state?this.search.state:null
                })
                    .then(res => {
                        this.tableLoading = false;
                        if(res.code===200||res.success){
                            res.data.records.forEach((item) => {
                                if (item.state == 1) {
                                    item['isShows'] = true;
                                } else {
                                    item['isShows'] = false;
                                }
                            });
                            this.data = res.data.records;
                            console.log(this.data);
                            this.pageSync.total = res.data.total;
                            this.pageSync.currentPage = res.data.current;
                        }else{
                            this.$message.error(res.msg);
                        }
                    })
                    .catch(res => {
                        this.tableLoading = false;
                        this.$message.error(res.msg);
                    });
            },

            searchList(){
                this.pageSync.currentPage = 1;
                this.getquestionList()
            },

            //编辑回显
            editQuestion(id){
                this.submitType=2
                questionView(id).then(res=>{
                    if(res.code==200){
                        this.form=res.data
                        this.question=true
                    }else{
                        this.$message.error(res.msg);
                    }
                }).catch(res=>{
                    this.$message.error(res.msg);
                })
            },

            //改变状态
            rowState(row) {
                let that = this;
                setQuestionState({
                    id: row.id,
                    state: row.state == 1 ? 2 : 1
                })
                    .then(res => {
                        that.getquestionList();
                        if(res.success){
                            this.$message.success(res.msg);
                        }else{
                            this.$message.error(res.msg);
                        }
                    })
                    .catch(res => {
                        that.getquestionList();
                        this.$message.error(res.msg);
                    });
            },
            // 确定按钮提交
            post_dialogVisible(){
                queryChapter(this.qid).then(res=>{
                    if(res.code==200){
                        if(res.data>0){
                            this.dialogVisible = false;
                        }else{
                            questionDelete(this.qid).then(res => {
                                // 获取数据成功后的其他操作
                                if (res.code === 200) {
                                    this.dialogVisible = false;
                                    this.getquestionList();
                                    this.$message.success(res.msg);
                                } else {
                                    this.$message.error(res.msg);
                                }
                            });
                        }
                    }else{
                        this.$message.error(res.msg);
                    }
                }).catch(res=>{
                    this.$message.error(res.msg);
                })
            },
            //查询是否存在关联章节
            getQueryChapter(id){
                queryChapter(id).then(res=>{
                    this.qid = id;
                    if(res.code==200){
                        if(res.data>0){
                            //存在关联数据
                            this.dialogVisible = true;
                            this.showCancel = false;
                            this.reminder = '无法删除';
                            /*this.tip = `该题库存在关联章节，无法删除;`+'\n'+
                            `请修改章节所属题库或删除关联章节后再进行删除题库删除！`;*/
                            /*this.tip = "该题库存在关联章节，无法删除;" +
                                "请修改章节所属题库或删除关联章节后再进行删除题库删除！";*/
                            this.tip = `<div>该题库存在关联章节，无法删除;</div><div>请修改章节所属题库或删除关联章节后再进行删除题库删除！</div>`
                        }else{
                            this.dialogVisible = true;
                            this.reminder = '删除';
                            this.tip = `是否确认删除,删除操作不可撤回!`;
                            this.showCancel = true;
                        }
                    }else{
                        this.$message.error(res.msg);
                    }
                }).catch(res=>{
                    this.$message.error(res.msg);
                })
            },

            toBookPointsList(id){
                this.$router.push({path:'/web/book/manage/bookPointsList',query: {id}})
            },


            //加载数据
            onLoad() {
                this.getquestionList()
            },
        }
    };
</script>

<style scoped>
    span{
        line-height: 35px;
    }
</style>
