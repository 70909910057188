<template>
    <el-dialog :title="submitType==1?'新增测试题':'编辑测试题'" :visible.sync="dialogVisible" width="700px" v-dialogdrag :destroy-on-close="true" :close-on-click-modal="false" :before-close="handleClose">
        <avue-form
                :option="option"
                v-model="fomes"
                :upload-error="uploadError"
                :upload-delete="uploadDelete"
                :upload-before="uploadBefore"
                :upload-after="uploadAfter"
                @submit="formSubmit"
                ref="bannerForm"
        >

            <template slot="aaa">
                <div>&emsp;（单图，建议尺寸：314px*148px） </div>
            </template>
            <template slot="sort">
                <el-input v-model="fomes.sort" autocomplete="off" type="text" maxlength="6"
                          placeholder="数值越小越靠前"
                          onkeyup="this.value=this.value.replace(/^(0+)|[^\d]+/g,'')"
                          @blur="salaryChange"/>
            </template>
            <template slot="answer" >
                <AvueUeditor v-model="fomes.answer" :options="options"></AvueUeditor>
            </template>
        </avue-form>

        <span slot="footer" class="dialog-footer">
			<el-button @click="handleClose">取消</el-button>
			<el-button type="primary" @click="formSubmit">保存</el-button>
		</span>
    </el-dialog>
</template>

<script>
    import { chapterQuestionAdd,chapterQuestionEdit } from '@/api/question/questionList';
    import { imageUrl, actionUrl } from '@/config/public';
    export default {
        name: 'add',
        props: {
            dialogVisible: Boolean,
            form:Object,//表单数据
            submitType:Number,//弹窗类别1：添加，2：编辑
            dialogForm: Object
        },
        data() {
            return {
                options: {
                    action: actionUrl,
                    headers: {
                        Authorization: this.$store.state.user.Token
                    },
                    props: {
                        res: "data",
                        url: "path",
                        name: 'path'
                    },
                },
                fomes: this.submitType === 1 ? this.dialogForm : this.form,
                data:[],
                innerVisible:false,
                optionTable:{
                    emptyText: '暂无数据',
                    stripe: false,
                    showHeader: true,
                    border: true,
                    align: 'center',
                    menuAlign: 'center',
                    columnBtn: false, //列显隐
                    refreshBtn: false, //刷新
                    saveBtn: false,
                    updateBtn: false,
                    cancelBtn: false,
                    addBtn: false,
                    delBtn: false,
                    editBtn: false,
                    excelBtn: false,
                },
                option: {
                    submitBtn: false,
                    emptyBtn: false,
                    column: [
                        {
                            label: '测试题ID',
                            prop: 'id',
                            disabled:true,
                            labelWidth: 100,
                            span:15,
                            display:this.submitType==1?false:true
                        },
                        {
                            label: '测试题标题',
                            prop: 'title',
                            placeholder:'最多500字',
                            maxlength:500,
                            labelWidth: 100,
                            span:15,
                            rules: [
                                {
                                    required: true,
                                    message: '请填写测试题标题',
                                    trigger: 'blur'
                                }
                            ],
                        },
                        {
                            //label: '书目封面图',
                            type: 'upload',
                            listType: 'picture-img',
                            accept:'image/jpeg,image/png',
                            span: 10,
                            labelWidth: 100,
                            fileSize:10240,
                            // row: true,
                            prop: 'image',
                            loadText: '附件上传中，请稍等',
                            // tip: 'jpg/png文件上传,大小不能超过10m',
                            propsHttp: {
                                res: 'data',
                                url: 'url',
                                name: 'path',
                                home: imageUrl
                            },
                            headers: {
                                Authorization: this.$store.state.user.Token
                            },
                            action: actionUrl,
                            /*rules: [
                                {
                                    required: true,
                                    message: '请添加图片',
                                    trigger: 'blur'
                                }
                            ]*/
                        },
                        {
                            label: '',
                            prop: 'aaa',
                            labelWidth: 0,
                            span: 10,
                            slot: true
                        },

                        {
                            label: '测试题答案',
                            prop: 'answer',
                            span: 24,
                            labelWidth: 100,
                            row: true,
                            rules: [
                                {
                                    required: true,
                                    message: '请输入内容',
                                    trigger: 'blur'
                                }
                            ]
                        },
                        {
                            label: '排序值',
                            prop: 'sort',
                            row: true,
                            /* type:'number',*/
                            span:15,
                            labelWidth: 100,
                            slot: true,
                            maxlength:50,
                            placeholder:'数值越小越靠前',
                            minRows:1,
                            precision:0,
                            maxRows:999999999,
                            rules: [
                                {
                                    required: true,
                                    message: '请输入排序值',
                                    trigger: 'blur'
                                }
                            ]
                        },
                        {
                            label: '状态',
                            prop: 'state',
                            type: 'radio',
                            row: true,
                            maxlength:50,
                            labelWidth: 100,
                            span:15,
                            dicData: [{ label: '禁用', value: 2 }, { label: '启用', value: 1 }],
                            rules: [
                                {
                                    required: true,
                                    message: '请选择状态',
                                    trigger: 'blur'
                                }
                            ]
                        },

                    ]
                },


            };
        },
        mounted(){

            /*if(this.submitType==1){
                this.fomes.title = '';
                this.fomes.image = '';
                this.fomes.answer = '';
                this.fomes.sort = null;
                this.fomes.state = 2;
            }*/
        },
        methods: {
            uploadDelete() {
                return this.$confirm(`是否确定移除？`);
            },
            uploadBefore(file, done) {
                var newFile = new File([file], file.name, { type: file.type });
                done(newFile);
            },
            uploadError(error, column) {
                if(error=="文件太大不符合"){
                    this.$message.error('图片超过10M无法上传!');
                }else{
                    this.$message.error('上传失败');
                }
                console.log(error, column);
            },
            uploadAfter(res, done) {
                done();
                // this.$message.success('上传后的方法')'
                console.log(res);
                // this.form.headPortrait = res.image;
                this.$nextTick(() => {
                    this.fomes.image = res.path;
                });
            },
            formResetForm() {
                this.$root.eventHub.$emit('addchapterQuestion_chapterQuestionList', false);
            },
            handleClose() {
                this.submitType === 1 && this.$emit('handleClose',this.fomes)
                this.$root.eventHub.$emit('addchapterQuestion_chapterQuestionList', false);
            },
            chapterQuestionAddFun(){
                chapterQuestionAdd({
                    chapterId: this.$route.query.id,
                    title: this.fomes.title,
                     image: this.fomes.image,
                    answer: this.fomes.answer,
                    sort: this.fomes.sort,
                    state: this.fomes.state,
                })
                    .then(res => {
                        if (res.code === 200) {
                            console.log(res);
                            this.$message.success(res.msg);
                            let form = {
                                state: 2
                            }
                            this.$emit('handleClose',form)
                            this.$root.eventHub.$emit('chapterQuestionAdd_chapterQuestionList', true);
                            this.$root.eventHub.$emit('addchapterQuestion_chapterQuestionList', false);
                            //done();
                        }else{
                            this.$message.error(res.msg);
                        }
                    })
                    .catch(err => {
                        this.$message.error(err.msg);
                    });
            },
            chapterQuestionEditFun(){
                chapterQuestionEdit({
                    id: this.fomes.id,
                    chapterId: this.$route.query.id,
                    title: this.fomes.title,
                    image: this.fomes.image,
                    answer: this.fomes.answer,
                    sort: this.fomes.sort,
                    state: this.fomes.state,
                })
                    .then(res => {
                        if (res.code === 200) {
                            console.log(res);
                            this.$message.success(res.msg);
                            let form = {
                                state: 2
                            }
                            this.$emit('handleClose',form)
                            this.$root.eventHub.$emit('chapterQuestionAdd_chapterQuestionList', true);
                            this.$root.eventHub.$emit('addchapterQuestion_chapterQuestionList', false);
                            //done();
                        }else{
                            this.$message.error(res.msg);
                        }
                    })
                    .catch(err => {
                        this.$message.error(err.msg);
                    });
            },
            formSubmit() {
                if(this.fomes.sort==null){
                    return this.$message.error("请输入排序值");
                }
                if(this.submitType==1){
                    this.chapterQuestionAddFun()
                }else{
                    this.chapterQuestionEditFun()
                }
            },
            salaryChange(e){
                this.fomes.sort = e.target.value;
            },

        }
    };
</script>

<style scoped></style>
