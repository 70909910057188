import { Delete, get, post, put } from '../axios'
//获取banner列表
export const bannerList = params => get(`/web/banner/manage/list`,params);
//状态修改
export const setUserState = params => put(`/web/banner/manage/updateState`,params);
//新增
export const bannerAdd = params => post(`/web/banner/manage/add`,params);
//编辑
export const bannerEdit = params => put(`/web/banner/manage/edit`,params);
//删除
export const deleteBanner = params => Delete(`/web/banner/manage/delete/${params}`);
//编辑回显
export const detailBanner = params => get(`/web/banner/manage/detail/${params}`);


