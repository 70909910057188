import Vue from 'vue'
import Router from 'vue-router'
Vue.use(Router)

// pages
// 登录
import login from '@/pages/login/login';
// 首页
import index from '@/pages/index/index';

// view 内容
// index首页
import indexHome from '@/view/index/index';

// system 系统管理
import systemUser from '@/view/system/user';
import systemRole from '@/view/system/role';

//用户列表
import userList from '@/view/user/manage';
import integralList from '@/view/user/integralRecord';

//banner管理
import bannerList from '@/view/banner/manage';

//书目管理
import bookList from '@/view/book/manage';
import bookPointslList from '@/view/book/bookPointslList';

//测试题库管理
import questionList from '@/view/question/manage';
import chapterList from '@/view/question/chapter';
import chapterQuestionList from '@/view/question/chapterQuestion';

//积分设置
import integralSetList from '@/view/integral/manage';

export default new Router({
	// mode: 'history',//去掉#号、
	mode: 'hash', //保留#号、
	routes: [
		{
			path: '/login',
			name: 'login',
			component: login,
		},
		{
			path: '/',
			component: index,
			children: [
				{
					path: '/',
					name: 'indexHome',
					component: indexHome
				},
				// ------------------------------------------------------ 系统管理
				{
					path: '/system/user',
					name: 'systemUser',
					component: systemUser
				},
				{
					path: '/system/role',
					name: 'role',
					component: systemRole
				},
				// ------------------------------------------------------ 企业信息

				//------------------------------------------------------- 用户列表
				{
					path: '/user/manage',
					name: 'userList',
					component: userList
				},
				{
					path: '/web/user/manage/integral',
					name: 'integralList',
					component: integralList
				},
				//------------------------------------------------------- banner管理
				{
					path: '/banner/manage',
					name: 'bannerList',
					component: bannerList
				},

				//------------------------------------------------------- 书目管理
				{
					path: '/book/manage',
					name: 'bookList',
					component: bookList
				},
				{
					path: '/web/book/manage/bookPointsList',
					name: 'bookPointslList',
					component: bookPointslList
				},

				//------------------------------------------------------- 测试题库管理
				//题库设置
				{
					path: '/question/manage',
					name: 'questionList',
					component: questionList
				},

				//章节内容管理
				{
					path: '/question/chapter',
					name: 'chapterList',
					component: chapterList
				},
				//测试题管理
				{
					path: '/web/question/chapterquestion/list',
					name: 'chapterQuestionList',
					component: chapterQuestionList
				},
				//------------------------------------------------------- 积分设置
				{
					path: '/integral/manage',
					name: 'integralSetList',
					component: integralSetList
				},

			]
		}
	]
})
