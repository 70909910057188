<template>
    <div class="main-content">
        <pagesHeader title="banner管理" />
        <div class="table-content">
            <avue-crud :data="data" :option="option" :search.sync="search" :page.sync="pageSync" :table-loading="tableLoading" @on-load="onLoad" ref="crud">
                <template slot="menuLeft">
                    <el-button type="primary" icon="el-icon-plus" size="medium" style="margin-left: 15px" @click.stop="addbanner = true ,submitType=1">新增</el-button>
                </template>
                <template slot="isShows" slot-scope="scope">
                    <avue-switch v-model="scope.row.isShows" @click="rowState(scope.row)"></avue-switch>
                </template>
                <template slot="menu" slot-scope="{ row }">
                    <!--<el-button style="margin-left:10px;" size="small" type="text" @click.stop="form = row,addbanner = true ,submitType=2">编辑</el-button>-->
                    <el-button style="margin-left:10px;" size="small" type="text" @click.stop="editBanner(row.id)">编辑</el-button>
                    <el-button style="margin-left:10px;" size="small" type="text" @click.stop="bannerDelete(row.id)">删除</el-button>
                </template>
            </avue-crud>
        </div>
        <bannerDialog :submitType='submitType' :form="form" v-if="addbanner" :dialogVisible="addbanner"></bannerDialog>
    </div>
</template>

<script>
    import bannerDialog from './bannerDialog.vue';
    import { bannerList,setUserState,deleteBanner,detailBanner  } from '@/api/banner/bannerList';
    export default {
        name: 'bannerList',
        components: {
            bannerDialog
        },
        data() {
            return {
                submitType:1,
                form: {},
                addbanner: false,
                tableLoading: false,
                exportLoading: false,
                search: {},
                data: [],
                option: {
                    emptyText: '暂无数据',
                    stripe: false,
                    showHeader: true,
                    border: true,
                    align: 'center',
                    menuAlign: 'center',
                    columnBtn: false, //列显隐
                    refreshBtn: false, //刷新
                    saveBtn: false,
                    updateBtn: false,
                    cancelBtn: false,
                    addBtn: false,
                    delBtn: false,
                    editBtn: false,
                    excelBtn: false,
                    column: [
                        {
                            label: '图片',
                            prop: 'image',
                            type: 'upload'
                        },
                        {
                            label: '排序值',
                            prop: 'sort'
                        },
                        {
                            label: '状态',
                            prop: 'isShows',
                            type: 'switch',
                            slot: false,
                            row: true
                        }
                    ]
                },
                page: 1,
                pageSize: 10,
                pageSync: {
                    currentPage: 1,
                    total: 0,
                    layout: 'total,prev,pager, next',
                    background: false,
                    pageSize: 10
                }
            };
        },
        mounted() {
            let that = this;
            that.$root.eventHub.$on('addbanner_bannerList', n => {
                that.addbanner = false;
            });
            that.$root.eventHub.$on('bannerAdd_bannerList', n => {
                that.addbanner = false;
                if (n) {
                    that.getBannerList();
                }
            });
        },
        methods: {
            editBanner(id){
                this.submitType=2,
                detailBanner(id).then(res=>{
                    if(res.code==200){
                        this.form=res.data
                        this.addbanner=true
                    }else{
                        this.$message.error(res.msg);
                    }
                }).catch(err=>{
                    this.$message.error(err.msg);
                })
            },
            // 删除banner
            bannerDelete(id) {
                let that = this;
                console.log(id);
                this.$confirm(`删除后数据清除，且无法撤回，再次确认是否删除！`, '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    callback: action => {
                        if (action == 'confirm') {
                            deleteBanner(id).then(res => {
                                // 获取数据成功后的其他操作
                                if (res.code === 200) {
                                    that.getBannerList();
                                    that.$message.success(res.msg);
                                } else {
                                    that.$message.error(res.msg);
                                }
                            });
                        }
                    }
                });
            },
            //获取banner列表
            getBannerList() {
                this.tableLoading = true;
                bannerList({
                    page: this.pageSync.currentPage,
                    size: this.pageSync.pageSize
                })
                    .then(res => {
                        this.tableLoading = false;
                        if(res.code===200||res.success){
                            res.data.records.forEach((item) => {
                                if (item.state == 1) {
                                    item['isShows'] = true;
                                } else {
                                    item['isShows'] = false;
                                }
                            });
                            this.data = res.data.records;
                            console.log(this.data);
                            this.pageSync.total = res.data.total;
                            this.pageSync.currentPage = res.data.current;
                        }else{
                            this.$message.error(res.msg);
                        }
                    })
                    .catch(res => {
                        this.tableLoading = false;
                        this.$message.error(res.msg);
                    });
            },
            //加载数据
            onLoad() {
                this.getBannerList();
            },
            //改变状态
            rowState(row) {
                let that = this;
                setUserState({
                    id: row.id,
                    state: row.state == 1 ? 2 : 1
                })
                    .then(res => {
                        that.getBannerList();
                        if(res.success){
                            this.$message.success(res.msg);
                        }else{
                            this.$message.error(res.msg);
                        }
                    })
                    .catch(res => {
                        that.getBannerList();
                        this.$message.error(res.msg);
                    });
            }
        }
    };
</script>

<style scoped></style>
