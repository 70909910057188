import { Delete, get, post, put } from '../axios'
//获取题库列表
export const questionList = params => get(`/web/question/manage/list`,params);
//编辑回显
export const questionView = params => get(`/web/question/manage/questionView/${params}`);
//新增
export const questionAdd = params => post(`/web/question/manage/add`,params);
//编辑
export const questionEdit = params => put(`/web/question/manage/edit`,params);
//状态修改
export const setQuestionState = params => put(`/web/question/manage/update`,params);
//查询是否存在关联章节
export const queryChapter = params => get(`/web/question/manage/queryChapter/${params}`);
//题库删除
export const questionDelete = params => Delete(`/web/question/manage/delete/${params}`);

//获取章节列表
export const chapterList = params => get(`/web/question/chapter/list`,params);
//章节编辑回显
export const chapterView = params => get(`/web/question/chapter/detail/${params}`);
//新增
export const chapterAdd = params => post(`/web/question/chapter/add`,params);
//编辑
export const chapterEdit = params => put(`/web/question/chapter/edit`,params);
//状态修改
export const setChapterState = params => put(`/web/question/chapter/update`,params);
//删除
export const chapterDelete = params => Delete(`/web/question/chapter/delete/${params}`);
//章节内容管理 题库名称列表
export const chooseList = params => get(`/web/question/manage/chooseList`,params);

//获取测试题列表
export const chapterQuestionList = params => get(`/web/question/chapterquestion/list`,params);
//状态修改
export const setChapterQuestionState = params => put(`/web/question/chapterquestion/update`,params);
///删除
export const chapterQuestionDelete = params => Delete(`/web/question/chapterquestion/delete/${params}`);
//编辑回显
export const chapterQuestionView = params => get(`/web/question/chapterquestion/detail/${params}`);
//新增
export const chapterQuestionAdd = params => post(`/web/question/chapterquestion/add`,params);
//编辑
export const chapterQuestionEdit = params => put(`/web/question//chapterquestion/edit`,params);