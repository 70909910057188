<template>
    <el-dialog :title="submitType==1?'新增知识点':'编辑知识点'" :visible.sync="dialogVisible" width="700px" :destroy-on-close="true" :close-on-click-modal="false" :before-close="handleClose">
        <avue-form
                :option="option"
                v-model="fomes"
                :upload-error="uploadError"
                :upload-delete="uploadDelete"
                :upload-before="uploadBefore"
                :upload-after="uploadAfter"
                @submit="formSubmit"
                ref="bannerForm"
        >

            <template slot="aaa">
                <div>&emsp;（单图，建议尺寸：314px*148px） </div>
            </template>
            <template slot="content" >
                <!--<vue-ueditor-wrap   v-model="fomes.content" :config="ueConfig" ></vue-ueditor-wrap>-->
                <AvueUeditor v-model="fomes.content" :options="options" ></AvueUeditor>
            </template>
        </avue-form>

        <span slot="footer" class="dialog-footer">
			<el-button @click="handleClose">取 消</el-button>
			<el-button type="primary" @click="formSubmit">保 存</el-button>
		</span>
    </el-dialog>
</template>

<script>
    import { bookPointsAdd,bookPointsEdit } from '@/api/book/bookList';
    //import VueUeditorWrap from 'vue-ueditor-wrap' // 引入vue-ueditor-wrap
    import { imageUrl, actionUrl } from '@/config/public';
    export default {
        name: 'add',
        /*components: {
            VueUeditorWrap	//注册组件
        },*/
        props: {
            dialogVisible: Boolean,
            form:Object,//表单数据
            submitType:Number,//弹窗类别1：添加，2：编辑
            dialogForm: Object
        },
        data() {
            return {
                options: {
                    action: actionUrl,
                    headers: {
                        Authorization: this.$store.state.user.Token
                    },
                    props: {
                        res: "data",
                        url: "path",
                        name: 'path'
                    },
                },
                fomes: this.submitType === 1 ? this.dialogForm : this.form,
                data:[],
                innerVisible:false,
                optionTable:{
                    emptyText: '暂无数据',
                    stripe: false,
                    showHeader: true,
                    border: true,
                    align: 'center',
                    menuAlign: 'center',
                    columnBtn: false, //列显隐
                    refreshBtn: false, //刷新
                    saveBtn: false,
                    updateBtn: false,
                    cancelBtn: false,
                    addBtn: false,
                    delBtn: false,
                    editBtn: false,
                    excelBtn: false,
                },
                option: {
                    submitBtn: false,
                    emptyBtn: false,
                    column: [
                        {
                            label: '知识点ID',
                            prop: 'id',
                            disabled:true,
                            labelWidth: 100,
                            span:15,
                            display:this.submitType==1?false:true
                        },
                        {
                            label: '知识点标题',
                            prop: 'title',
                            placeholder:'最多50字',
                            maxlength:50,
                            span:15,
                            labelWidth: 100,
                            rules: [
                                {
                                    required: true,
                                    message: '请填写知识点标题',
                                    trigger: 'blur'
                                }
                            ]
                        },
                        {
                            //label: '书目封面图',
                            type: 'upload',
                            listType: 'picture-img',
                            accept:'image/jpeg,image/png',
                            span: 10,
                            labelWidth: 100,
                            fileSize:10240,
                            // row: true,
                            prop: 'image',
                            loadText: '附件上传中，请稍等',
                            // tip: 'jpg/png文件上传,大小不能超过10m',
                            propsHttp: {
                                res: 'data',
                                url: 'url',
                                name: 'path',
                                home: imageUrl
                            },
                            headers: {
                                Authorization: this.$store.state.user.Token
                            },
                            action: actionUrl,
                            /*rules: [
                                {
                                    required: true,
                                    message: '请添加图片',
                                    trigger: 'blur'
                                }
                            ]*/
                        },
                        {
                            label: '',
                            prop: 'aaa',
                            labelWidth: 0,
                            span: 10,
                            slot: true
                        },

                        {
                            label: '知识点内容',
                            prop: 'content',
                            span: 24,
                            labelWidth: 100,
                            row: true,
                            rules: [
                                {
                                    required: true,
                                    message: '请输入内容',
                                    trigger: 'blur'
                                }
                            ]
                        },
                        {
                            label: '状态',
                            prop: 'state',
                            type: 'radio',
                            row: true,
                            maxlength:50,
                            labelWidth: 100,
                            span:15,
                            dicData: [{ label: '禁用', value: 2 }, { label: '启用', value: 1 }],
                            rules: [
                                {
                                    required: true,
                                    message: '请选择状态',
                                    trigger: 'blur'
                                }
                            ]
                        },

                    ]
                },

                ueConfig:{
                    toolbars: [
                        [
                            'fullscreen', 'source', '|',//html
                            'undo', //撤销
                            'bold', //加粗
                            'indent', //首行缩进
                            'italic', //斜体
                            'underline', //下划线
                            'strikethrough', //删除线
                            'subscript', //下标
                            'fontborder', //字符边框
                            'superscript', //上标
                            'formatmatch', //格式刷
                            'fontfamily', //字体
                            'justifyleft', //居左对齐
                            'justifycenter', //居中对齐
                            'justifyright', //居右对齐
                            'fontsize', //字号
                            'justifyjustify', //两端对齐
                            'insertorderedlist', //有序列表
                            'insertunorderedlist', //无序列表
                            'lineheight',//行间距
                            'simpleupload',//上传图片
                        ]
                    ],
                    'fontfamily':[
                        { label:'',name:'songti',val:'宋体,SimSun'},
                        { label:'仿宋',name:'fangsong',val:'仿宋,FangSong'},
                        { label:'仿宋_GB2312',name:'fangsong',val:'仿宋_GB2312,FangSong'},
                        { label:'',name:'kaiti',val:'楷体,楷体_GB2312, SimKai'},
                        { label:'',name:'yahei',val:'微软雅黑,Microsoft YaHei'},
                        { label:'',name:'heiti',val:'黑体, SimHei'},
                        { label:'',name:'lishu',val:'隶书, SimLi'},
                        { label:'',name:'andaleMono',val:'andale mono'},
                        { label:'',name:'arial',val:'arial, helvetica,sans-serif'},
                        { label:'',name:'arialBlack',val:'arial black,avant garde'},
                        { label:'',name:'comicSansMs',val:'comic sans ms'},
                        { label:'',name:'impact',val:'impact,chicago'},
                        { label:'',name:'timesNewRoman',val:'times new roman'}
                    ],
// 初始容器高度
                    initialFrameHeight: 300,
// 初始容器宽度
                    initialFrameWidth: '100%',
// 上传文件接口
                    enableAutoSave: false,
                    elementPathEnable: false,
                    wordCount: false,
                    UEDITOR_HOME_URL: '/static/UEditor/',
                    serverUrl: "api/web/tool/file/upload", //图片上传的地址
                    token:this.$store.state.user.Token,
                },

            };
        },
        mounted(){


            // if(this.submitType==1){
            //     this.fomes.title = '';
            //     this.fomes.image = '';
            //     this.fomes.content = '';
            //     this.fomes.state = 2;
            // }
        },
        methods: {
            uploadDelete() {
                return this.$confirm(`是否确定移除？`);
            },
            uploadBefore(file, done) {
                var newFile = new File([file], file.name, { type: file.type });
                done(newFile);
            },
            uploadError(error, column) {
                if(error=="文件太大不符合"){
                    this.$message.error('图片超过10M无法上传!');
                }else{
                    this.$message.error('上传失败');
                }
                console.log(error, column);
            },
            uploadAfter(res, done) {
                done();
                // this.$message.success('上传后的方法')'
                console.log(res);
                // this.form.headPortrait = res.image;
                this.$nextTick(() => {
                    this.fomes.image = res.path;
                });
            },
            formResetForm() {
                this.$root.eventHub.$emit('addbookPoints_bookPointsList', false);
            },
            handleClose() {
                this.submitType === 1 && this.$emit('handleClose',this.fomes)
                this.$root.eventHub.$emit('addbookPoints_bookPointsList', false);
            },
            bookPointsAddFun(){
                bookPointsAdd({
                    bookId: this.$route.query.id,
                    title: this.fomes.title,
                    image: this.fomes.image,
                    content: this.fomes.content,
                    state: this.fomes.state,
                })
                    .then(res => {
                        if (res.code === 200) {
                            console.log(res);
                            this.$message.success(res.msg);
                            let form = {
                                state: 2
                            }
                            this.$emit('handleClose',form)
                            this.$root.eventHub.$emit('bookPonitsAdd_bookPointsList', true);
                            this.$root.eventHub.$emit('addbookPoints_bookPointsList', false);
                            //done();
                        }else{
                            this.$message.error(res.msg);
                        }
                    })
                    .catch(err => {
                        this.$message.error(err.msg);
                    });
            },
            bookPointsEditFun(){
                bookPointsEdit({
                    id: this.fomes.id,
                    bookId: this.fomes.bookId,
                    title: this.fomes.title,
                    image: this.fomes.image,
                    content: this.fomes.content,
                    state: this.fomes.state,
                })
                    .then(res => {
                        if (res.code === 200) {
                            console.log(res);
                            this.$message.success(res.msg);
                            let form = {
                                state: 2
                            }
                            this.$emit('handleClose',form)
                            this.$root.eventHub.$emit('bookPonitsAdd_bookPointsList', true);
                            this.$root.eventHub.$emit('addbookPoints_bookPointsList', false);
                            //done();
                        }else{
                            this.$message.error(res.msg);
                        }
                    })
                    .catch(err => {
                        this.$message.error(err.msg);
                    });
            },
            formSubmit() {
                if(this.submitType==1){
                    this.bookPointsAddFun()
                }else{
                    this.bookPointsEditFun()
                }
            },
            salaryChange(e){
                this.fomes.sort = e.target.value;
            },

        }
    };
</script>

<style scoped></style>
