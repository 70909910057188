<template>
    <el-dialog :title="submitType==1?'新增书目':'编辑书目'" :visible.sync="dialogVisible" width="700px" v-dialogdrag :destroy-on-close="true" :close-on-click-modal="false" :before-close="handleClose">
        <avue-form
                :option="option"
                v-model="fomes"
                :upload-error="uploadError"
                :upload-delete="uploadDelete"
                :upload-before="uploadBefore"
                :upload-after="uploadAfter"
                @submit="formSubmit"
                ref="bannerForm"
        >

            <template slot="aaa">
                <div>&emsp;（建议尺寸：102px*144px） </div>
            </template>
            <template slot="sort">
                <el-input v-model="fomes.sort" autocomplete="off" type="text" maxlength="6"
                          placeholder="数值越大越靠前"
                          onkeyup="this.value=this.value.replace(/^(0+)|[^\d]+/g,'')"
                          @blur="salaryChange"/>
            </template>
        </avue-form>

        <span slot="footer" class="dialog-footer">
			<el-button @click="handleClose">取 消</el-button>
			<el-button type="primary" @click="formSubmit">确 定</el-button>
		</span>
    </el-dialog>
</template>

<script>
    import { bookAdd,bookEdit } from '@/api/book/bookList';
    import { imageUrl, actionUrl } from '@/config/public';
    export default {
        name: 'add',
        props: {
            dialogVisible: Boolean,
            form:Object,//表单数据
            submitType:Number//弹窗类别1：添加，2：编辑
        },
        data() {
            return {
                fomes:this.form,
                data:[],
                innerVisible:false,
                optionTable:{
                    emptyText: '暂无数据',
                    stripe: false,
                    showHeader: true,
                    border: true,
                    align: 'center',
                    menuAlign: 'center',
                    columnBtn: false, //列显隐
                    refreshBtn: false, //刷新
                    saveBtn: false,
                    updateBtn: false,
                    cancelBtn: false,
                    addBtn: false,
                    delBtn: false,
                    editBtn: false,
                    excelBtn: false,
                },
                option: {
                    submitBtn: false,
                    emptyBtn: false,
                    column: [
                        {
                            label: '书目ID',
                            prop: 'id',
                            disabled:true,
                            labelWidth: 100,
                            span:15,
                            display:this.submitType==1?false:true
                        },
                        {
                            label: '书目名称',
                            prop: 'name',
                            placeholder:'最多30字',
                            maxlength:30,
                            span:15,
                            labelWidth: 100,
                            rules: [
                                {
                                    required: true,
                                    message: '请填写书目名称',
                                    trigger: 'blur'
                                }
                            ]
                        },
                        {
                            label: '书目封面图',
                            type: 'upload',
                            listType: 'picture-img',
                            accept:'image/jpeg,image/png',
                            span: 10,
                            labelWidth: 100,
                            fileSize:10240,
                            // row: true,
                            prop: 'cover',
                            loadText: '附件上传中，请稍等',
                            // tip: 'jpg/png文件上传,大小不能超过10m',
                            propsHttp: {
                                res: 'data',
                                url: 'url',
                                name: 'path',
                                home: imageUrl
                            },
                            headers: {
                                Authorization: this.$store.state.user.Token
                            },
                            action: actionUrl,
                            rules: [
                                {
                                    required: true,
                                    message: '请上传封面图',
                                    trigger: 'blur'
                                }
                            ]
                        },
                        {
                            label: '',
                            prop: 'aaa',
                            labelWidth: 0,
                            span: 10,
                            slot: true
                        },

                        {
                            label: '排序值',
                            prop: 'sort',
                            row: true,
                            span:15,
                            slot: true,
                            labelWidth: 100,
                            maxlength:50,
                            //placeholder:'数值越大越靠前',
                            minRows:1,
                            precision:0,
                            maxRows:999999999,
                            rules: [
                                {
                                    required: true,
                                    message: '请输入排序值',
                                    trigger: 'blur'
                                }
                            ]
                        }

                    ]
                }
            };
        },
        mounted(){

            if(this.submitType==1){
                this.fomes.name = '';
                this.fomes.cover = '';
                this.fomes.sort = '';
            }
        },
        methods: {
            uploadDelete() {
                return this.$confirm(`是否确定移除？`);
            },
            uploadBefore(file, done) {
                var newFile = new File([file], file.name, { type: file.type });
                done(newFile);
            },
            uploadError(error, column) {
                if(error=="文件太大不符合"){
                    this.$message.error('图片超过10M无法上传!');
                }else{
                    this.$message.error('上传失败');
                }
                console.log(error, column);
            },
            uploadAfter(res, done) {
                done();
                // this.$message.success('上传后的方法')'
                console.log(res);
                // this.form.headPortrait = res.image;
                this.$nextTick(() => {
                    this.fomes.cover = res.path;
                });
            },
            formResetForm() {
                this.$root.eventHub.$emit('addbook_bookList', false);
            },
            handleClose() {
                this.$root.eventHub.$emit('addbook_bookList', false);
            },
            bookAddFun(){
                bookAdd({
                    name: this.fomes.name,
                    cover: this.fomes.cover,
                    sort: this.fomes.sort
                })
                    .then(res => {
                        if (res.code === 200) {
                            console.log(res);
                            this.$message.success(res.msg);
                            this.$root.eventHub.$emit('bookAdd_bookList', true);
                            this.$root.eventHub.$emit('addbook_bookList', false);
                            //done();
                        }else{
                            this.$message.error(res.msg);
                        }
                    })
                    .catch(err => {
                        this.$message.error(err.msg);
                    });
            },
            bookEditFun(){
                bookEdit({
                    id: this.fomes.id,
                    name: this.fomes.name,
                    cover: this.fomes.cover,
                    sort: this.fomes.sort
                })
                    .then(res => {
                        if (res.code === 200) {
                            console.log(res);
                            this.$message.success(res.msg);
                            this.$root.eventHub.$emit('bookAdd_bookList', true);
                            this.$root.eventHub.$emit('addbook_bookList', false);
                            //done();
                        }else{
                            this.$message.error(res.msg);
                        }
                    })
                    .catch(err => {
                        this.$message.error(err.msg);
                    });
            },
            formSubmit() {
                if(this.fomes.sort==null){
                    return this.$message.error("请输入排序值");
                }
                if(this.submitType==1){
                    this.bookAddFun()
                }else{
                    this.bookEditFun()
                }
            },
            salaryChange(e){
                this.fomes.sort = e.target.value;
            },

        }
    };
</script>

<style scoped></style>
