<template>
    <el-dialog :title="submitType==1?'新增章节':'编辑章节'" :visible.sync="dialogVisible" width="700px" v-dialogdrag :destroy-on-close="true" :close-on-click-modal="false" :before-close="handleClose">
        <avue-form
                :option="option"
                v-model="fomes"
                :upload-error="uploadError"
                :upload-delete="uploadDelete"
                :upload-before="uploadBefore"
                :upload-after="uploadAfter"
                @submit="formSubmit"
                ref="bannerForm"
        >

            <template slot="sort">
                <el-input v-model="fomes.sort" autocomplete="off" type="text" maxlength="6"
                          placeholder="数值越小越靠前"
                          onkeyup="this.value=this.value.replace(/^(0+)|[^\d]+/g,'')"
                          @blur="salaryChange"/>
            </template>
        </avue-form>

        <span slot="footer" class="dialog-footer">
			<el-button @click="handleClose">取消</el-button>
			<el-button type="primary" @click="formSubmit">保存</el-button>
		</span>
    </el-dialog>
</template>

<script>
    import {chapterAdd, chapterEdit} from "@/api/question/questionList";
    export default {
        name: 'add',
        props: {
            dialogVisible: Boolean,
            form:Object,//表单数据
            submitType:Number,//弹窗类别1：添加，2：编辑
            serachQuestionList:Array,
            search:Array
        },
        data() {
            return {
                fomes:this.form,
                data:[],
                innerVisible:false,
                optionTable:{
                    emptyText: '暂无数据',
                    stripe: false,
                    showHeader: true,
                    border: true,
                    align: 'center',
                    menuAlign: 'center',
                    columnBtn: false, //列显隐
                    refreshBtn: false, //刷新
                    saveBtn: false,
                    updateBtn: false,
                    cancelBtn: false,
                    addBtn: false,
                    delBtn: false,
                    editBtn: false,
                    excelBtn: false,
                },
                option: {
                    submitBtn: false,
                    emptyBtn: false,
                    column: [
                        {
                            label: '章节ID',
                            prop: 'id',
                            disabled:true,
                            labelWidth: 110,
                            span: 15,
                            display:this.submitType==1?false:true
                        },
                        {
                            label: '章节名称',
                            prop: 'name',
                            placeholder:'最多50字',
                            maxlength:50,
                            span:15,
                            labelWidth: 110,
                            rules: [
                                {
                                    required: true,
                                    message: '请填写章节名称',
                                    trigger: 'blur'
                                }
                            ],
                        },
                        {
                            label: '所属题库名称',
                            prop: 'questionId',
                            row: true,
                            type: 'select',
                            multiple: false,
                            span: 15,
                            labelWidth: 110,
                            dicData:this.serachQuestionList,
                            rules: [
                                {
                                    required: true,
                                    message: '请选择题库名称',
                                    trigger: 'blur'
                                }
                            ],
                            props: { label: "label", value: "value"},
                        },
                        {
                            label: '排序值',
                            prop: 'sort',
                            row: true,
                            span:15,
                            labelWidth: 110,
                            slot: true,
                            maxlength:50,
                            //placeholder:'数值越小越靠前',
                            minRows:1,
                            precision:0,
                            maxRows:999999999,
                            rules: [
                                {
                                    required: true,
                                    message: '数值越小越靠前',
                                    trigger: 'blur'
                                }
                            ]
                        },

                        {
                            label: '状态',
                            prop: 'state',
                            type: 'radio',
                            row: true,
                            maxlength:50,
                            labelWidth: 110,
                            span:15,
                            dicData: [{ label: '禁用', value: 2 }, { label: '启用', value: 1 }],
                            rules: [
                                {
                                    required: true,
                                    message: '请选择状态',
                                    trigger: 'blur'
                                }
                            ]
                        },

                    ]
                }
            };
        },
        mounted(){

            if(this.submitType==1){
                this.fomes.name = '';
                this.fomes.questionId = '';
                this.fomes.sort = null;
                this.fomes.state = 2;
            }
        },
        methods: {
            uploadDelete() {
                return this.$confirm(`是否确定移除？`);
            },
            uploadBefore(file, done) {
                var newFile = new File([file], file.name, { type: file.type });
                done(newFile);
            },
            uploadError(error, column) {
                if(error=="文件太大不符合"){
                    this.$message.error('图片超过10M无法上传!');
                }else{
                    this.$message.error('上传失败');
                }
                console.log(error, column);
            },
            uploadAfter(res, done) {
                done();
                // this.$message.success('上传后的方法')'
                console.log(res);
                // this.form.headPortrait = res.image;
                this.$nextTick(() => {
                    this.fomes.bannerUrl = res.path;
                });
            },
            formResetForm() {
                this.$root.eventHub.$emit('addchapter_chapteList', false);
            },
            handleClose() {
                this.$root.eventHub.$emit('addchapter_chapteList', false);
            },
            chapterAddFun(){
                chapterAdd({
                    name: this.fomes.name,
                    questionId: this.fomes.questionId,
                    sort: this.fomes.sort,
                    state: this.fomes.state
                })
                    .then(res => {
                        if (res.code === 200) {
                            console.log(res);
                            this.$message.success(res.msg);
                            this.$root.eventHub.$emit('chapterAdd_chapterList', true);
                            this.$root.eventHub.$emit('addchapter_chapteList', false);
                            //done();
                        }else{
                            this.$message.error(res.msg);
                        }
                    })
                    .catch(err => {
                        this.$message.error(err.msg);
                    });
            },
            chapterEditFun(){
                chapterEdit({
                    id: this.fomes.id,
                    name: this.fomes.name,
                    questionId: this.fomes.questionId,
                    sort: this.fomes.sort,
                    state: this.fomes.state
                })
                    .then(res => {
                        if (res.code === 200) {
                            console.log(res);
                            this.$message.success(res.msg);
                            this.$root.eventHub.$emit('chapterAdd_chapterList', true);
                            this.$root.eventHub.$emit('addchapter_chapteList', false);
                            //done();
                        }else{
                            this.$message.error(res.msg);
                        }
                    })
                    .catch(err => {
                        this.$message.error(err.msg);
                    });
            },
            formSubmit() {
                // console.log("fomes",this.fomes)
                if(this.fomes.sort==null){
                    return this.$message.error("请输入排序值");
                }
                if(this.submitType==1){
                    this.chapterAddFun()
                }else{
                    this.chapterEditFun()
                }
            },
            salaryChange(e){
                this.fomes.sort = e.target.value;
            },

        }
    };
</script>

<style scoped></style>
