<template>
    <div class="main-content">
        <pagesHeader title="知识点管理" />
        <div class="table-content">
            <avue-crud :data="data" :option="option" :search.sync="search" :page.sync="pageSync" :table-loading="tableLoading" @on-load="onLoad" ref="crud">
                <template slot="menuLeft">
                    <div style="display: flex;width: 100%;">
                        <el-button type="primary" icon="el-icon-plus" size="medium" style="margin-left: 15px" @click.stop="bookPoints = true ,submitType=1">新增知识点</el-button>
                        <span>&emsp;知识点ID:&emsp;</span>
                        <el-input clearable v-model="search.id"    prefix-icon="el-icon-search" style="width: 250px" ></el-input>
                        <span>&emsp;知识点标题:&emsp;</span>
                        <el-input clearable v-model="search.title"   prefix-icon="el-icon-search" style="width: 250px" ></el-input>
                        <span>&emsp;状态:</span>
                        <div style="width: 100px;margin: 0 10px;">
                            <el-select clearable v-model="search.state" >
                                <el-option v-for="item in typeoption" :key="item.value" :label="item.label" :value="item.value">
                                </el-option>
                            </el-select>
                        </div>
                        <el-button type="primary" size="medium" style="margin-left: 15px" @click.stop="searchList">&emsp;&emsp;查询&emsp;&emsp;</el-button>
                    </div>
                </template>
                <template slot="isShows" slot-scope="scope">
                    <avue-switch v-model="scope.row.isShows" @click="rowState(scope.row)"></avue-switch>
                </template>
                <template slot="menu" slot-scope="{ row }">
                    <el-button style="margin-left:10px;" size="small" type="text" @click.stop="editBookPoint(row.id)">编辑</el-button>
                    <el-button style="margin-left:10px;" size="small" type="text" @click.stop="bookPonitsDelete(row.id)">删除</el-button>
                </template>
            </avue-crud>
        </div>

        <bookPointsListDialog  :submitType='submitType' :form="form" v-if="bookPoints" :dialogVisible="bookPoints" @handleClose="handleClose" :dialogForm="dialogForm"></bookPointsListDialog>
    </div>
</template>

<script>
    import bookPointsListDialog from './bookPointsListDialog.vue';
    import { bookPointsList,bookPointsView,bookPointsUpdate,deleteBookPoints} from '@/api/book/bookList';

    export default {
        name: 'bookPointsList',
        components: {
            bookPointsListDialog
        },
        data() {
            return {
                schoolTypeList:[],
                provincelist: [],
                submitType:1,
                typeoption: [{
                    value: null,
                    label: '全部'
                }, {
                    value: 1,
                    label: '启用'
                }, {
                    value: 2,
                    label: '禁用'
                }],
                search: {state:null},
                form: { },
                bookPoints:false,
                nameOrPhone:'',
                typevalue: '',
                date: '',
                tableLoading: false,
                exportLoading: false,
                data: [],
                option: {
                    emptyText: '暂无数据',
                    stripe: false,
                    showHeader: true,
                    border: true,
                    align: 'center',
                    menuAlign: 'center',
                    columnBtn: false, //列显隐
                    refreshBtn: false, //刷新
                    saveBtn: false,
                    updateBtn: false,
                    cancelBtn: false,
                    addBtn: false,
                    // saveBtnTitle:'11',
                    delBtn: false,
                    editBtn: false,
                    excelBtn: false,
                    //index:true,
                    indexLabel:'序号',
                    column: [
                        {
                            label: '知识点ID',
                            prop: 'id'

                        },
                        {
                            label: '知识点标题',
                            prop: 'title'
                        },
                        {
                            label: '状态',
                            prop: 'isShows',
                            type: 'switch',
                            slot: false,
                            row: true
                        }

                    ]
                },
                page: 1,
                pageSize: 10,
                pageSync: {
                    currentPage: 1,
                    total: 0,
                    layout: 'total,prev,pager, next',
                    background: false,
                    pageSize: 10
                },
                dialogForm:{
                    state: 2
                }
            };
        },

        mounted() {
            let that = this;
            that.$root.eventHub.$on('addbookPoints_bookPointsList', n => {
                that.bookPoints = false;
            });
            that.$root.eventHub.$on('bookPonitsAdd_bookPointsList', n => {
                that.bookPoints = false;
                if (n) {
                    that.getbookPointsList();
                }
            });
        },
        methods: {
            handleClose(e){
                this.dialogForm = e;
            },

            //获取书目列表
            getbookPointsList() {
                this.tableLoading = true;
                bookPointsList({
                    page: this.pageSync.currentPage,
                    size: this.pageSync.pageSize,
                    bookId:this.$route.query.id,
                    id: this.search.id?this.search.id:'',
                    title: this.search.title?this.search.title:'',
                    state: this.search.state?this.search.state:null
                })
                    .then(res => {
                        this.tableLoading = false;
                        if(res.code===200||res.success){
                            res.data.records.forEach((item) => {
                                if (item.state == 1) {
                                    item['isShows'] = true;
                                } else {
                                    item['isShows'] = false;
                                }
                            });
                            this.data = res.data.records;
                            console.log(this.data);
                            this.pageSync.total = res.data.total;
                            this.pageSync.currentPage = res.data.current;
                        }else{
                            this.$message.error(res.msg);
                        }
                    })
                    .catch(res => {
                        this.tableLoading = false;
                        this.$message.error(res.msg);
                    });
            },

            searchList(){
                this.pageSync.currentPage = 1;
                this.getbookPointsList()
            },

            //编辑回显
            editBookPoint(id){
                this.submitType=2
                bookPointsView(id).then(res=>{
                    if(res.code==200){
                        this.form=res.data,
                        this.bookPoints=true
                    }else{
                        this.$message.error(res.msg);
                    }
                }).catch(res=>{
                    this.$message.error(res.msg);
                })
            },

            toBookPointsList(id){
                this.$router.push({path:'/web/book/manage/bookPointsList',query: {id}})
            },

            //改变状态
            rowState(row) {
                let that = this;
                bookPointsUpdate({
                    id: row.id,
                    state: row.state == 1 ? 2 : 1
                })
                    .then(res => {
                        that.getbookPointsList();
                        if(res.success){
                            this.$message.success(res.msg);
                        }else{
                            this.$message.error(res.msg);
                        }
                    })
                    .catch(res => {
                        that.getbookList();
                        this.$message.error(res.msg);
                    });
            },



            // 删除知识点
            bookPonitsDelete(id) {
                let that = this;
                console.log(id);
                this.$confirm(`删除后关联数据均会删除，再次确认是否删除！`, '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    callback: action => {
                        if (action == 'confirm') {
                            deleteBookPoints(id).then(res => {
                                // 获取数据成功后的其他操作
                                if (res.code === 200) {
                                    that.getbookPointsList();
                                    that.$message.success(res.msg);
                                } else {
                                    that.$message.error(res.msg);
                                }
                            });
                        }
                    }
                });
            },


            //加载数据
            onLoad() {
                this.getbookPointsList()
            },
        }
    };
</script>

<style scoped>
    span{
        line-height: 35px;
    }
</style>
