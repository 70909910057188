<template>
    <div class="main-content">
        <pagesHeader title="积分设置" />
        <div style="margin-left: 50px">
        <div class="item" style="text-align: center">
            <label style="width: 350px">单日学习任务完成获得&emsp;</label>
            <el-input v-model="form.studyGetIntegral"
                      autocomplete="off"
                      type="digit"
                      placeholder="非负整数"
                      maxlength="6"
                      onkeyup="this.value=this.value.replace(/[^\d]+/g,'')"
                      @blur="salaryChange($event,'studyGetIntegral')"/>
            <label style="width: 120px">积分</label>
        </div>
        <div class="item" >
            <label style="width: 400px">累计完成</label>
            <el-input v-model="form.studyCountDay"
                      autocomplete="off"
                      type="digit"
                      placeholder="正整数"
                      maxlength="6"
                      onkeyup="this.value=this.value.replace(/^(0+)|[^\d]+/g,'')"
                      @blur="salaryChange($event,'studyCountDay')"/>
            <label style="width: 900px">&emsp;个单日学习任务获得</label>
            <el-input v-model="form.studyCountIntegral"
                      autocomplete="off"
                      type="digit"
                      placeholder="非负整数"
                      maxlength="6"
                      onkeyup="this.value=this.value.replace(/[^\d]+/g,'')"
                      @blur="salaryChange($event,'studyCountIntegral')"/>
            <label style="width: 250px">&emsp;积分</label>
        </div>
        <div class="item">
            <label style="width: 280px">分享新用户注册获得</label>
            <el-input v-model="form.shareRegisterIntegral"
                      autocomplete="off"
                      type="digit"
                      placeholder="非负整数"
                      maxlength="6"
                      onkeyup="this.value=this.value.replace(/[^\d]+/g,'')"
                      @blur="salaryChange($event,'shareRegisterIntegral')"/>
            <label style="width: 100px">&emsp;积分</label>
        </div>
        <div class="item" >
            <label style="width: 80px">消耗</label>
            <el-input v-model="form.unlockChapterIntegral"
                      autocomplete="off"
                      type="digit"
                      placeholder="非负整数"
                      maxlength="6"
                      onkeyup="this.value=this.value.replace(/[^\d]+/g,'')"
                      @blur="salaryChange($event,'unlockChapterIntegral')"/>
            <label style="width: 300px">&emsp;积分解锁一个章节</label>
        </div>
        </div>

            <div class="submit-button" style="text-align: center">
                <el-button class="saveBtn"  type="primary" size="medium" @click="handleSubmit(form)">保存</el-button>
            </div>
    </div>
</template>


<script>
    import {detailIntegral,updateIntegral} from "@/api/integral/integralList";

    export default {
        name: "integralList",
        data(){
            return {
                schoolTypeList:[],
                provincelist: [],
                submitType:1,
                nameOrPhone:'',
                typevalue: '',
                date: '',
                tableLoading: false,
                exportLoading: false,
                data: [],
                form:{
                    studyGetIntegral:null,
                    studyCountDay:null,
                    studyCountIntegral:null,
                    shareRegisterIntegral:null,
                    unlockChapterIntegral:null,

                },
            }
        },
        mounted() {
            this.getDetailList();
        },
        methods: {

            salaryChange(e,t){
                this.form[t] = e.target.value;
            },
            //获取积分明细
            getDetailList() {
                detailIntegral({})
                    .then(res => {
                        if(res.code===200||res.success){
                            this.form.id = res.data.id;
                            this.form.studyGetIntegral = res.data.studyGetIntegral,
                            this.form.studyCountDay = res.data.studyCountDay,
                            this.form.studyCountIntegral = res.data.studyCountIntegral,
                            this.form.shareRegisterIntegral = res.data.shareRegisterIntegral,
                            this.form.unlockChapterIntegral = res.data.unlockChapterIntegral
                        }else{
                            this.$message.error(res.msg);
                        }
                    })
                    .catch(res => {
                        this.tableLoading = false;
                        this.$message.error(res.msg);
                    });
            },

            handleSubmit(form){
                updateIntegral({
                    id: form.id,
                    studyGetIntegral:form.studyGetIntegral,
                    studyCountDay:form.studyCountDay,
                    studyCountIntegral:form.studyCountIntegral,
                    shareRegisterIntegral:form.shareRegisterIntegral,
                    unlockChapterIntegral:form.unlockChapterIntegral,
                })
                    .then(res => {
                        if(res.code===200||res.success){
                            this.getDetailList();
                            this.$message.success(res.msg);
                        }else{
                            this.$message.error(res.msg);
                        }
                    })
                    .catch(res => {
                        this.$message.error(res.msg);
                    });

            }


        },



    }
</script>

<style scoped>
   /* .title {
        font-weight: bold;
    }*/
    .item {
        display: flex;
        margin: 30px 0px;
        width: 500px;
    }
   /* .item_value {
        margin-left: 40px;
    }
    .item_lebar {
        width: 200px;
        display: flex;
    }
    .item_lebar_2 {
        width: 130px;
        display: flex;
    }
    .value_image {
        width: 100px;
        height: 100px;
    }*/
    .submit-button{
        width:calc(100vw - 240px);
        position: fixed;
        left:240px;
        bottom: 100px;
    }
    .saveBtn{
        width: 200px;
        height: 35px;
        line-height:7px;
    }
   label{
       line-height: 40px;
   }

</style>