<template>
    <div class="main-content">
        <pagesHeader title="用户列表" />
        <div class="table-content">
            <avue-crud :data="data" :option="option" :search.sync="search" :page.sync="pageSync" :table-loading="tableLoading" @on-load="onLoad" ref="crud">
                <template slot="menuLeft">
                    <div style="display: flex;width: 100%;">
                        <span>用户ID:&emsp;</span>
                        <el-input clearable v-model="search.id"    prefix-icon="el-icon-search" style="width: 250px" ></el-input>
                        <span>&emsp;昵称:&emsp;</span>
                        <el-input clearable v-model="search.nickname"   prefix-icon="el-icon-search" style="width: 250px" ></el-input>
                        <span>&emsp;手机号:&emsp;</span>
                        <el-input clearable v-model="search.phone"  prefix-icon="el-icon-search" style="width: 250px" ></el-input>
                        <span>&emsp;状态:</span>
                        <div style="width: 100px;margin: 0 10px;">
                            <el-select clearable v-model="search.state" >
                                <el-option v-for="item in typeoption" :key="item.value" :label="item.label" :value="item.value">
                                </el-option>
                            </el-select>
                        </div>
                        <el-button type="primary" size="medium" style="margin-left: 15px" @click.stop="searchList">&emsp;&emsp;查询&emsp;&emsp;</el-button>
                    </div>
                </template>
                <template slot="avatarUrl" slot-scope="scope">
                    <el-image
                            style="width: 45px;height: 45px"
                            :src="scope.row.avatarUrl"
                            :preview-src-list="[scope.row.avatarUrl]">
                    </el-image>
                </template>
                <template slot="isShows" slot-scope="scope">
                    <avue-switch v-model="scope.row.isShows" @click="rowState(scope.row)"></avue-switch>
                </template>
                <template slot="menu" slot-scope="{ row }">
                    <el-button style="margin-left:10px;" size="small" type="text" @click.stop="cliclIntegral(row.id)">积分记录</el-button>
                </template>
            </avue-crud>
        </div>

    </div>
</template>

<script>
    import { userList,setUserState } from '@/api/user/userList';
    export default {
        name: 'userList',
        data() {
            return {

                schoolTypeList:[],
                provincelist: [],
                submitType:1,
                form: { },
                school: false,
                nameOrPhone:'',
                typeoption: [{
                    value: null,
                    label: '全部'
                }, {
                    value: 1,
                    label: '启用'
                }, {
                    value: 2,
                    label: '禁用'
                }],
                typevalue: '',
                date: '',
                tableLoading: false,
                exportLoading: false,
                search: {state:null},
                data: [],
                option: {
                    emptyText: '暂无数据',
                    stripe: false,
                    showHeader: true,
                    border: true,
                    align: 'center',
                    menuAlign: 'center',
                    columnBtn: false, //列显隐
                    refreshBtn: false, //刷新
                    saveBtn: false,
                    updateBtn: false,
                    cancelBtn: false,
                    addBtn: false,
                    // saveBtnTitle:'11',
                    delBtn: false,
                    editBtn: false,
                    excelBtn: false,
                    //index:true,
                    column: [
                        {
                            label: '用户ID',
                            prop: 'id'
                        },
                        {
                            label: '昵称',
                            prop: 'nickname'
                        },
                        {
                            label: '头像',
                            prop: 'avatarUrl',
                            type: 'img'
                        },
                        {
                            label: '手机号',
                            prop: 'phone'
                        },
                        {
                            label: '状态',
                            prop: 'isShows',
                            type: 'switch',
                            slot: false,
                            row: true
                        },
                        {
                            label: '当前可用积分',
                            prop: 'integral'
                        }
                    ]
                },
                page: 1,
                pageSize: 10,
                pageSync: {
                    currentPage: 1,
                    total: 0,
                    layout: 'total,prev,pager, next',
                    background: false,
                    pageSize: 10
                }
            };
        },

        methods: {

            //获取用户列表
            getUserList() {
                this.tableLoading = true;
                userList({
                    page: this.pageSync.currentPage,
                    size: this.pageSync.pageSize,
                    id: this.search.id?this.search.id:'',
                    nickname: this.search.nickname?this.search.nickname:'',
                    phone: this.search.phone?this.search.phone:'',
                    state: this.search.state?this.search.state:null
                })
                    .then(res => {
                        this.tableLoading = false;
                        if(res.code===200||res.success){
                            res.data.records.forEach((item) => {
                                if (item.state == 1) {
                                    item['isShows'] = true;
                                } else {
                                    item['isShows'] = false;
                                }
                            });
                            this.data = res.data.records;
                            console.log(this.data);
                            this.pageSync.total = res.data.total;
                            this.pageSync.currentPage = res.data.current;
                        }else{
                            this.$message.error(res.msg);
                        }
                    })
                    .catch(res => {
                        this.tableLoading = false;
                        this.$message.error(res.msg);
                    });
            },


            searchList(){
                this.pageSync.currentPage = 1;
                this.getUserList()
            },

            //改变状态
            rowState(row) {
                let that = this;
                setUserState({
                    id: row.id,
                    state: row.state == 1 ? 2 : 1
                })
                    .then(res => {
                        that.getUserList();
                        if(res.success){
                            this.$message.success(res.msg);
                        }else{
                            this.$message.error(res.msg);
                        }
                    })
                    .catch(res => {
                        that.getUserList();
                        this.$message.error(res.msg);
                    });
            },

            cliclIntegral(id){
                    this.$router.push({path:'/web/user/manage/integral',query: {id}})
            },

            //加载数据
            onLoad() {
                this.getUserList();
            },
        }
    };
</script>

<style scoped>
    span{
        line-height: 35px;
    }
</style>
